import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton } from "@mui/material";

import classNames from "classnames";
import { ReactNode } from "react";
import styles from './index.module.scss';


interface FlyModalProps extends Omit<DialogProps, 'onClose' | 'title'> {
  title?: string | ReactNode
  className?: string
  footer?: ReactNode
  onClose: () => void
  onOk?: () => void
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const DialogTitleHOC = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2,fontSize:'1.05rem',width:"94%",mt:1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};



export default function FlyModal(props: FlyModalProps) {
  const { className, open, onClose, onOk, footer } = props;

  return (
    <Dialog
      open={open}
      aria-labelledby="customized-dialog-title"
      className={classNames(styles.flyModal, className)}
      onClose={onClose}
      maxWidth="lg"
    >

      <DialogTitleHOC
        id="customized-dialog-title"
        onClose={onClose}

      >
        { props.title }
      </DialogTitleHOC>

      <DialogContent>{ props.children }</DialogContent>

      <DialogActions>
          {
            (footer === null || footer) ? footer :
              <>
                <Button onClick={onClose} variant="outlined" sx={{color:"#36d4c7",borderColor:'rgba(54,212,199,0.5)'}}>取消</Button>
                <Button onClick={onOk} variant="contained" sx={{backgroundColor:"#36d4c7"}}>确认</Button>
              </>
          }
      </DialogActions>
    </Dialog>
  )
}