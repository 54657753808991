import "./App.css";

import Router from "@/router/index";
import { defaultTheme } from "@/theme";
import { ThemeProvider } from "@mui/material/styles";
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { pageInitIfUserUnLoginService } from "./service/user";
import {consumeWxLoginCode} from "@/utils/wxlogin";
import {preventOpenDevtool} from "@/utils";

function App() {
    useEffect(() => {
        consumeWxLoginCode().then(() => {
            pageInitIfUserUnLoginService().then(() => {
				React.$bury.beginBury();
			})
			preventOpenDevtool()
        })
    }, [])

    return (
        <ThemeProvider theme={defaultTheme}>
            <BrowserRouter>
                {/*<Suspense fallback={<div>loading...</div>}>*/}
                {/*    {Router}*/}
                {/*</Suspense>*/}
                {Router}
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
