import {stopBury} from "@/utils/bury/actions";

export default function () {
	const {aplus_queue} = window;
	stopBury();
	// 集成应用的appKey
	aplus_queue.push({
		action: 'aplus.setMetaInfo',
		arguments: ['appKey', '6457aa0f7dddcc5bad441ead']
	});
	// 启用手动pv
	aplus_queue.push({
		action: 'aplus.setMetaInfo',
		arguments: ['aplus-waiting', 'MAN']
	});
	// 开启debugger
	aplus_queue.push({
		action: 'aplus.setMetaInfo',
		arguments: ['DEBUG', process.env.NODE_ENV !== 'production']
	});
}
