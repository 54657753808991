// 反馈类型
export const enum feedbackEnum {
    SUGGEST = 1,
    COMPLAINTS = 2,
    BUG = 3,
    OTHER = 99
}

export const feedbackTypeOptions  = [
    {
        name: '建议',
        value: feedbackEnum.SUGGEST
    },
    {
        name: '投诉',
        value: feedbackEnum.COMPLAINTS
    },
    {
        name: '故障',
        value: feedbackEnum.BUG
    },
    {
        name: '其他',
        value: feedbackEnum.OTHER
    }
]

// 绘制反馈
export const enum PaintFeedbackEnum {
    STYLE = 'STYLE', // 风格
    ARTIST = 'ARTIST', // 艺术家
    RENDER = 'RENDER', // 渲染
    HUE = 'HUE', // 色调
    SCENE = 'SCENE', // 场景
    VISUAL_ANGLE = 'VISUAL_ANGLE', // 视角
    IMAGE_SCALE = 'IMAGE_SCALE', // 画布大小
}

export const PaintFeedbackInfoMap: Record<string, string> = {
    [PaintFeedbackEnum.STYLE] : '想要的风格不存在？点我反馈赠送一天内免费绘画',
    [PaintFeedbackEnum.ARTIST] : '想要的艺术家不存在？点我反馈赠送一天内免费绘画',
    [PaintFeedbackEnum.RENDER] : '想要的渲染不存在？点我反馈赠送一天内免费绘画',
    [PaintFeedbackEnum.HUE] : '想要的色调不存在？点我反馈赠送一天内免费绘画',
    [PaintFeedbackEnum.SCENE] : '想要的场景不存在？点我反馈赠送一天内免费绘画',
    [PaintFeedbackEnum.VISUAL_ANGLE] : '想要的视角不存在？点我反馈赠送一天内免费绘画',
    [PaintFeedbackEnum.IMAGE_SCALE] : '想要的画布大小不存在？点我反馈赠送一天内免费绘画'
}

// 粉丝/关注
export const enum FollowEnum {
    FOLLOW,
    FANS
}

// 消息通知
export const enum NotificationEnum {
    SYSTEM, // 系统消息
    LIKE, // 点赞
    COLLECT, // 收藏
    COMMENT, // 评论
    FOLLOW, // 关注
}
export const NotificationText: Record<number, string> = {
    [NotificationEnum.SYSTEM]: '系统消息',
    [NotificationEnum.LIKE]: '点赞',
    [NotificationEnum.COLLECT]: '收藏',
    [NotificationEnum.COMMENT]: '评论',
    [NotificationEnum.FOLLOW]: '关注',
}

// 模式、模型右上角的hot、new图标
export const enum IconTypeEnum {
    EMPTY,
    NEW,
    HOT,
}