import {ConfirmProp} from "@/components/confirmBox/interface";
import {Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useCallback} from "react";
import GlobalDialog from "@/components/globalDialog/globalDialog/globalDialog";


export default function ConfirmBox(props: ConfirmProp) {
    const {title, content, cancelText, confirmText, onConfirm, onCancel} = props;
    const handleCancel = useCallback(() => {
        GlobalDialog.clear();
        onCancel && onCancel();
    }, [onCancel]);
    const handleConfirm = useCallback(() => {
        onConfirm && onConfirm();
    }, [onConfirm]);

    return <div>
        {
            title ? <DialogTitle>{title}</DialogTitle> : null
        }
        {
            content ? <DialogContent>{content}</DialogContent> : null
        }
        <DialogActions>
            <Button onClick={handleCancel} sx={{color: '#8f8f8f'}}>
                {
                    cancelText || '取消'
                }
            </Button>
            <Button autoFocus onClick={handleConfirm} sx={{color: '#54b2a3'}}>
                {
                    confirmText || '确定'
                }
            </Button>
        </DialogActions>
    </div>
}