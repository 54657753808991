import { show } from "./show";
import { clear } from "./clear";
import { confirm } from './confirm'
import { attachPropertiesToComponent } from "@/components/globalDialog/utils/attachPropertiesToComponent";
import { Modal } from "@mui/material";

export default attachPropertiesToComponent(Modal, {
	show,
	clear,
    confirm,
});
