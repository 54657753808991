import { Location } from "@remix-run/router";
import React, {ForwardedRef, useMemo} from "react";
import { NavigateFunction, useLocation, useNavigate, useParams } from "react-router-dom";

export interface withRouterProps {
    history: {
        push: NavigateFunction
        params: any
        location: Location
    }
    comRef: ForwardedRef<unknown>
}

export interface CurrentNavigateProps {
    current: NavigateFunction | null
}

export let currentNavigate: CurrentNavigateProps = {
    current: null
};

export function injectNavigate(nav: NavigateFunction) {
    currentNavigate.current = nav;
}

export default function useWithRouterHook<T>(Component: any): any {
    const push = useNavigate();
    const params = useParams();
    const location = useLocation();

    return useMemo(() => {
		return React.forwardRef((props: T, ref) => {
			return <Component {...props} history={{ push, params, location }} comRef={ref}/>;
		})
	}, [push, params, location])
}
