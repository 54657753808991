import { useEffect, useState, useRef } from "react";

const useCountDown = (s: number) => {
  const [seconds, setSeconds] = useState<any>(s);
  const getPhone = useRef<boolean>(true);
  useEffect(() => {
    setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
        if (seconds === 1) getPhone.current = true; // 1的时候就要设为true，不然已经显示“获取验证码”但要1s后才能点击
      }
    }, 1000);
  }, [seconds]);

  return [seconds, setSeconds, getPhone];
};

export default useCountDown
