import { lazy } from "react";

const Competition = lazy(() => import('@/pages/competition/list'))
const CompetitionDetail = lazy(() => import('@/pages/competition/detail'))
const CreateCompetition = lazy(() => import('@/pages/competition/create'))
const CompetitionWorkDetail = lazy(() => import('@/pages/competition/workDetail'))

const CompetitionRouter = [
    {
        path: '/ai/competition',
        element: <Competition />,
        title:"AI大赛-飞链云版图"
    },
    {
        path: '/ai/competition/:id',
        element: <CompetitionDetail />,
        title:"AI大赛-飞链云版图"
    },
    {
        path: '/ai/competition/create',
        element: <CreateCompetition />,
        title:"AI大赛投稿-飞链云版图"
    },
    {
        path: '/ai/competition/work/:id',
        element: <CompetitionWorkDetail />,
        title:"AI投稿详情-飞链云版图"
    }
]

export default CompetitionRouter