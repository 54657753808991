import GlobalDialog from "@/components/globalDialog/globalDialog/globalDialog";
import GlobalSnackbar from "@/components/globalDialog/globalSnackbar";
import SliderCaptcha from 'rc-slider-captcha';
import { createPuzzle } from 'create-puzzle';
import DemoImage from './sunflower.jpg';
import { webStatus } from "@/api/user";
import Style from './sliderCaptchaDialog.module.scss';
import { useRef } from "react";
import { DialogShowHandler } from "@/components/globalDialog/globalDialog/show";

interface IProps{
  handleVerifyCode: (ack: string, x: number) => void;
  validatePhone: () => boolean;
}
export default function SilderDialog(props: IProps){
  const {  handleVerifyCode } = props;
  const offsetXRef = useRef<number>(0);
  const ack = useRef<string>('');
  const dialogRef = useRef<DialogShowHandler>();

  function openDialog(){
    if(!props.validatePhone()) return;
    dialogRef.current = GlobalDialog.show({
      children: <div className={Style.sliderCaptchaBox}>
        <div className={Style.sliderCaptchaTitle}>安全验证</div>
        <SliderCaptcha
          className={Style.sliderCaptcha}
          request={async () => {
            const data: { ack: string; x: number } = await webStatus();
            ack.current = data.ack;
            const puzzle = await createPuzzle(DemoImage, {
              format: 'blob',
              x: data.x,
            });
            offsetXRef.current = puzzle.x;
            return {
              bgUrl: puzzle.bgUrl,
              puzzleUrl: puzzle.puzzleUrl,
            };
          }}
          tipText={{
            loadFailed: '加载失败，点击重新加载',
          }}
          onVerify={async (data: any) => {
            if (data?.x && data.x > (offsetXRef.current - 33) && data.x < (offsetXRef.current + 27)) {
              dialogRef.current!.close();
              GlobalSnackbar.show({icon: "success", message: "短信已发送",});
              handleVerifyCode(ack.current, data?.x);
              return Promise.resolve();
            }
            return Promise.reject();
          }}
        />
      </div>
    })
  }

  return <>
    <span onClick={openDialog}>获取验证码</span>
  </>
}
