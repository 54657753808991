import {
	AiStudyBaseFunctionRes,
	BaseComment,
	CommentReq,
	ContributeKeyword,
	CreateComment,
	ExhibitionItem,
	ExhibitionReq,
	FeedbackItem,
	FeedbackReq,
	GetPaintPatternListParams,
	ICollectlist,
	IFollowList,
	IPersoanlInfo,
	IScreen,
	ImgTagsItem,
	IndexSlideshow,
	KeywordOrder,
	ListRes,
	Member,
	MemberPriceItem,
	MsgItem,
	MsgReq,
	MultipleImgItem,
	MultipleRespListData,
	PageResultAiPromptCustomTagsModel,
	PageResultAiUserCustomPromptModel,
	Pagination,
	PaintFeedbackReq,
	PaintListQuery,
	PaintPatternType,
	PaintRequestDetail,
	PaintRequestSpeedDetail,
	PayData,
	PayDetail,
	PayReq,
	Pc_index_options,
	Pc_models,
	Pc_paint_hotSearchKeywords_data,
	ResolutionEnlarge,
	SceneType,
	ShareConfig,
	StarPriceItem,
	TagItem,
	VersionUpdate,
	GetFavoritesPaintParams,
	PrintPatternSample
} from "@/api/feilianyun/type";

import createInstance from "@/api/instance";

const instance = createInstance(process.env.REACT_APP_FEI_API!);

// 获取反馈类型枚举
export function getFeedbackEnum() {
	return instance<FeedbackItem[]>({
		method: "GET",
		url: "/feedback/feedback/functional",
	});
}

// 提交反馈
export function createFeedback(opts: Partial<PaintFeedbackReq>) {
	return instance({
		method: "POST",
		url: "feedback/feedback/functional/create",
		data: opts
	});
}


// 获取首页轮播图
export function getIndexSlideshow() {
	return instance<IndexSlideshow>({
		method: "GET",
		url: "/index/top/slideshow",
	});
}

// 获取版本更新内容
export function getVersionUpdate() {
	return instance<VersionUpdate>({
		method: "GET",
		url: "/index/version/milestone",
	});
}

// 退订版本更新通知
export function unsubscribe() {
	return instance({
		method: "GET",
		url: "/index/version/unsubscribe",
	});
}

// 获取模型
export function pc_models(opts: any) {
	return instance<Pc_models>({
		method: "GET",
		url: "/models",
		params: opts,
	});
}

// 获取首页选项接口
export function pc_options(opts: any) {
	return instance<Partial<Pc_index_options>>({
		method: "GET",
		url: "/v2/options",
		params: opts,
	});
}

// ai创作，获取场景list
export function getPaintSceneList() {
	return instance<SceneType[]>({
		method: "GET",
		url: "/getPaintSceneList",
	});
}

// ai创作，获取场景list
export function getPaintPatternList(params: GetPaintPatternListParams) {
	return instance<PaintPatternType>({
		method: "POST",
		url: "/getPaintPatternList",
		data: {
			...params,
			page: 1,
			pageSize: 999
		}
	});
}

// ai创作，收藏模型
export function getFavoritesPaint(params: GetFavoritesPaintParams) {
	return instance<PaintPatternType>({
		method: "POST",
		url: "/favoritesPaintPattern",
		data: params
	});
}


// 获取艺术展分类筛选
export function pc_screen() {
	return instance<IScreen>({
		method: "GET",
		url: "/index/screen",
	});
}

// 获取热门关键词title  升级v2
export function pc_hotKey_title(opts: any) {
	return instance<Pc_paint_hotSearchKeywords_data>({
		method: "POST",
		url: "/hotkey/title/query/v2",
		data: opts,
	});
}

// 获取热门关键词content  升级v2
export function pc_hotKey_content(opts: any) {
	return instance({
		method: "GET",
		url: "/hotkey/content/query/v2",
		params: opts,
	});
}

// 获取绘图详情
export function pc_paint_detail(code: string) {
	return instance<PaintRequestDetail>({
		method: "GET",
		url: "/draw/detail",
		params: { code },
	});
}

// 获取上一个/下一个作品
export function getPrevOrNextDetail(opts: {
	currentId: number;
	action: "prev" | "next";
}) {
	return instance<PaintRequestDetail>({
		method: "POST",
		url: "/draw/detail/next",
		data: opts,
	});
}

// 解锁关键词
export function pc_unlock_keyword(opts: { keyword: string }) {
	return instance<PaintRequestDetail>({
		method: "GET",
		url: "/unLock/keyword",
		params: opts,
	});
}

// 绘图
export function pc_drawing(opts: any) {
	return instance({
		method: "POST",
		url: "/v2/draw",
		data: opts,
	});
}

// 绘画进度详情
export function pc_paint_speed_detail(opts: any) {
	return instance<PaintRequestSpeedDetail>({
		method: "GET",
		url: "/speed/detail",
		params: opts,
	});
}

//绘制进度
export function paint_speed_detail(url: string, opts: any) {
	if (url) {
		return instance<PaintRequestSpeedDetail>({
			method: 'GET',
			url: url,
			params: opts
		});
	}
	return Promise.reject(false);
}

//绘制结果
export function paint_result(url: string, opts: any) {
	if (url) {
		return instance<PaintRequestSpeedDetail>({
			method: 'GET',
			url: url,
			params: opts
		});
	}
	return Promise.reject(false);
}

// 绘画结果 轮询调用
export function pc_paint_results(opts: any) {
	return instance({
		method: "GET",
		url: "/detail",
		params: opts,
	});
}


// 上传图片
export function pc_upload(opts: any) {
	return instance({
		method: "POST",
		url: "upload/img",
		data: opts,
	});
}

// 图片进行超分辨率放大
export function resolution_enlarge(opts: ResolutionEnlarge) {
	return instance({
		method: "POST",
		url: "super/resolution/enlarge",
		data: opts,
	});
}

// 获取图片超分辨率的类型以及相关消耗的积分
export function pc_resp_list() {
	return instance<MultipleRespListData>({
		method: "GET",
		url: "/super/resolution/info",
	});
}

// 获取图片超分辨率的图片列表
export function pc_multiple_imgList(code: string) {
	return instance<MultipleImgItem[]>({
		method: "GET",
		url: "/super/resolution/img",
		params: { code },
	});
}

// 获取分享设置
export function pc_share_config(code: string) {
	return instance<ShareConfig>({
		method: "GET",
		url: "/settings",
		params: { code },
	});
}

// 保存分享设置
export function pc_save_share_config(opt: ShareConfig) {
	return instance<string>({
		method: "POST",
		url: "/share/settings",
		data: opt,
	});
}

// 点赞
export function pc_like(code: string) {
	return instance<string>({
		method: "GET",
		url: "/like",
		params: { code },
	});
}

// 取消点赞
export function pc_dislike(code: string) {
	return instance<string>({
		method: "GET",
		url: "/dislike",
		params: { code },
	});
}

// 作品售卖 TODO
export function salePaint(opt: { code: string; price: string }) {
	return instance<string>({
		method: "POST",
		url: "/works/sale",
		data: opt,
	});
}
// 关注 0-取消关注，1-进行关注
export function pc_follow(follow: number, userId: number) {
	return instance<string>({
		method: "GET",
		url: "/user/follow",
		params: { follow, userId },
	});
}

// 收藏操作: 0-取消收藏，1-进行收藏
export function pc_collect(code: string, collect: number) {
	return instance<string>({
		method: "GET",
		url: "/user/collect",
		params: { code, collect },
	});
}

// 我的关注和我的粉丝列表0-关注列表，1-粉丝列表
export function pc_followList({ pageNo, pageSize, type, userId }: any) {
	return instance<IFollowList>({
		method: "GET",
		url: "/user/follow/page",
		params: { pageNo, pageSize, type, userId },
	});
}

// 我的收藏列表
export function pc_collectPage(pageNo: number, pageSize: number) {
	return instance<ICollectlist>({
		method: "GET",
		url: "/user/collect/page",
		params: { pageNo, pageSize },
	});
}

// 获取个人主页信息
export function pc_personalInfo(userId?: number | null) {
	return instance<IPersoanlInfo>({
		method: "GET",
		url: "/user/data/info",
		params: { userId },
	});
}

// 作品展出至广场
export function publishPaint(opt: { code: string; title?: string, scope?: number }) {
	return instance<string>({
		method: "POST",
		url: "/exhibition/publish",
		data: opt,
	});
}

// 获取画展列表
export function getExhibitions(opt: ExhibitionReq) {
	return instance<ListRes<ExhibitionItem>>({
		method: "POST",
		url: "/exhibitions",
		data: opt,
	});
}

// 根据模式id获取效果图
export function getPrintPatternSample(opt:PrintPatternSample) {
	return instance<ListRes<ExhibitionItem>>({
		method: "POST",
		url: "/getPrintPatternSample",
		data: opt,
	});
}

// 画同款调用查询  升级V2
export function pc_paint_again(opt: any) {
	return instance({
		method: "GET",
		url: "/paint/again/v2",
		params: opt,
	});
}

// 获取画夹总数和已经画的数量
export function getPaintData() {
	return instance({
		method: "GET",
		url: "/user/paint/data",
	});
}

// 获取画图尺寸列表 
export function getImageScaleEnum() {
	return instance({
		method: "GET",
		url: "/imageScaleEnum",
	});
}


// ----------- 商城相关 ---------
// 获取产品价格列表
export function getMemberPrice() {
	return instance<MemberPriceItem[]>({
		method: "GET",
		url: "/member/price",
	});
}

// 获取会员信息
export function getMemberInfo() {
	return instance<Member>({
		method: "GET",
		url: "member/info",
	});
}

// 获取飞星价格列表
export function getStarPrice() {
	return instance<StarPriceItem[]>({
		method: "GET",
		url: "/member/product/price",
	});
}

// 微信/支付宝发起支付
export function launchPay(opt: PayReq) {
	return instance<PayData>({
		method: "POST",
		url: "/fly/pay/launchPay",
		data: opt,
	});
}

// 获取订单详情
export function getPayDetail(orderNo: string) {
	return instance<PayDetail>({
		method: "POST",
		url: "/fly/pay/getOrderDetail",
		data: { orderNo },
	});
}

// 获取分享海报
export function getSharePoster() {
	return instance<string>({
		method: "GET",
		url: "/share/poster",
	});
}

// 我的画夹
export function getMyAiPaintList(opt: PaintListQuery) {
	return instance<ListRes<ExhibitionItem>>({
		method: "POST",
		url: "/paint/list",
		data: opt,
	});
}

// 删除我的画夹
export function deleteMyAiPaintList(codes: string[]) {
	return instance<string>({
		method: "POST",
		url: "/album/delete",
		data: { codes },
	});
}

// 一键清空我的画夹
export function cleanMyAiPaintList() {
	return instance<string>({
		method: "POST",
		url: "/album/clean",
		data: {},
	});
}

// 增加标签
export function addTags(opt: { tags: TagItem[] }) {
	return instance<boolean>({
		method: "POST",
		url: "/tags/batch/add",
		data: opt,
	});
}

// 获取画夹标签
export function getTags(opt: Pagination) {
	return instance<ListRes<TagItem>>({
		method: "POST",
		url: "/tags/query",
		data: opt,
	});
}

// 批量删除标签
export function deleteTags(tagIds: number[]) {
	return instance<boolean>({
		method: "POST",
		url: "/tags/batch/delete",
		data: { tagIds },
	});
}

// 编辑标签
export function editTags(opt: { id: number; tagName: string }) {
	return instance<boolean>({
		method: "POST",
		url: "/tags/update",
		data: opt,
	});
}

// 批量清空标签
export function batchClearTags(code: string[]) {
	return instance<boolean>({
		method: "POST",
		url: "/tags/img/batch/clear",
		data: { code },
	});
}

// 给图片打标签
export function batchTagToImg(imgTagsParamList: ImgTagsItem[]) {
	return instance<boolean>({
		method: "POST",
		url: "/tags/img/batch/add",
		data: { imgTagsParamList },
	});
}

// 获取单个图片的标签
export function getImgTagList(code: string) {
	return instance({
		method: "GET",
		url: "/tags/img/detail",
		params: { code },
	});
}

// 删除单个图片的标签
export function deleteImgTag(opt: {
	code: string;
	id: number;
	tags: string[];
}) {
	return instance<boolean>({
		method: "POST",
		url: "/tags/img/batch/remove",
		data: opt,
	});
}

// 获取说明文案  升级V2
export function index_param_intro() {
	return instance({
		method: "GET",
		url: "/index/param/intro/v2",
	});
}

// 用户反馈
export function addFeedback(data: FeedbackReq) {
	return instance<boolean>({
		method: "POST",
		url: "/feedback/add",
		data,
	});
}

// 判断画作是否被锁
export function getPaintLockStatus(code: string) {
	return instance<KeywordOrder>({
		method: "GET",
		url: "/keyword/locked",
		params: { code },
	});
}

// 获取解锁订单
export function keyword_unlock_order(data: any) {
	return instance({
		method: "POST",
		url: "/keyword/unlock/order",
		data,
	});
}

// 用户的画展列表
interface IUserExhibitions {
	pageIndex: number;
	pageSize: number;
	userId: number;
}
export function Pc_user_exhibitions(data: IUserExhibitions) {
	return instance({
		method: "POST",
		url: "/user/exhibitions",
		data,
	});
}
// 同步至图库
export function syncToAlbum(codes: string[]) {
	return instance({
		method: "POST",
		url: "/tuku/img/synchronization",
		data: { codes },
	});
}

// 获取psd
export function getPsd(code: string) {
	return instance({
		method: "GET",
		url: "/generatePsd",
		params: { code },
		timeout: 300000,
	});
}

// 用户模型的排序配置保存  升级v2
export function userConfigModel(arr: { sceneId: string | number, sort: number }[]) {
	return instance({
		method: 'POST',
		url: '/user/config/v2/scene',
		data: {
			sceneValues: arr
		}
	})
}

// 用户模式的排序配置保存   接口升级v2
export function userConfigPattern(sceneId: string | number, arr: { patternId: string | number, sort: number }[]) {
	return instance({
		method: 'POST',
		url: '/user/config/v2/pattern',
		data: {
			sceneId,
			patternValues: arr
		}
	})
}

// 高级选项的排序配置保存
export function advancedOptionsSort(type: string | number, arr: { value: string | number, sort: number }[]) {
	return instance({
		method: 'POST',
		url: '/user/config/advancedOptions',
		data: {
			type,
			valueReqs: arr
		}
	})
}


// 下载无水印图片扣减积分
export function getNoWraper(code: string) {
	return instance({
		method: "GET",
		url: "/download/deduction/integral",
		params: { code },
		timeout: 300000,
	});
}

// 用户邀请新人任务排行榜
export interface IInviteRank {
	createTime: string
	email: string
	headImg: string
	id: number
	inviteUserId: number
	nickname: string
	phone: string
	regSource: number
	statistics: number
	status: number
	statusMsg: string
	updateTime: string
}
export function getInviteRankList(type: number) {
	return instance<IInviteRank[]>({
		method: "GET",
		url: "/user/task/invite/ranking/list",
		params: { type },
	});
}

// 添加自定义提示词标签
export function promptTagAdd(data: any) {
	return instance({
		method: 'POST',
		url: '/prompt/tag/add',
		data
	})
}

// 创建提示词记录
export function promptCustomCreate(data: any) {
	return instance({
		method: 'POST',
		url: '/prompt/custom/create',
		data
	})
}

// 修改提示词记录
export function promptCustomUpdate(data: any) {
	return instance({
		method: 'POST',
		url: '/prompt/custom/update',
		data
	})
}

// 删除提示词记录
export function promptCustomDelete(data: any) {
	return instance({
		method: 'GET',
		url: '/prompt/custom/delete',
		params: data
	})
}

// 获取提示词标签列表
export function promptTagQuery(data: any) {
	return instance<PageResultAiPromptCustomTagsModel>({
		method: 'POST',
		url: '/prompt/tag/query',
		data
	})
}

// 获取提示词列表
export function promptCustomQuery(data: any) {
	return instance<PageResultAiUserCustomPromptModel>({
		method: 'POST',
		url: '/prompt/custom/query',
		data
	})
}

// 获取画作评论列表
export function getCommentList(data: CommentReq) {
	return instance<ListRes<BaseComment>>({
		method: 'POST',
		url: '/comments/list',
		data
	})
}

// 发布评论
export function publishComment(data: CreateComment) {
	return instance<boolean>({
		method: 'POST',
		url: '/comments/save',
		data
	})
}

// 删除评论
export function deleteComment(id: number) {
	return instance<boolean>({
		method: 'GET',
		url: '/comments/del',
		params: { id }
	})
}

// 投稿关键词
export function contributeKeyword(data: ContributeKeyword) {
	return instance<boolean>({
		method: 'POST',
		url: '/hot/keyword/contribute',
		data
	})
}

// 首页AI研究基地功能列表
export function aiStudyBaseFunction() {
	return instance<AiStudyBaseFunctionRes[]>({
		method: 'GET',
		url: '/ai/study/base/function',
	})
}

// 首页梦工厂功能列表
export function dreamWorksFunction() {
	return instance<AiStudyBaseFunctionRes[]>({
		method: 'GET',
		url: '/dream/works/function',
	})
}

// 消息列表
export function getMsgList(data: MsgReq) {
	return instance<ListRes<MsgItem>>({
		method: 'POST',
		url: '/msg/query',
		data
	})
}

// 消息已读
export function readMsg(msgId: number) {
	return instance<ListRes<MsgItem>>({
		method: 'GET',
		url: '/msg/read',
		params: { msgId }
	})
}

// 阅读全部消息
export function readAllMsg() {
	return instance<ListRes<MsgItem>>({
		method: 'GET',
		url: '/msg/all/read',
		params: {}
	})
}

//获取的我的关键词记录
export function fetchKeywordRecord(data: any) {
	return instance<any>({
		method: 'POST',
		url: '/keyword/history/query',
		data: data
	})
}
