import { getStsTemporaryToken } from '@/api/user';
import OSS from 'ali-oss';
import { useCallback, useRef } from "react";
import SparkMD5 from 'spark-md5';
import useUser from './useUser';

export const useUploadImg = () => {
	const { hasLogin } = useUser();

	const OSSClientRef = useRef<any>();
	const dirRef = useRef<any>();
	const ossHostRef = useRef();

	const getToken = useCallback(() => {
		return getStsTemporaryToken();
	}, [])

	const getOSSClient = useCallback(() => {

		const initOSS = async () => {
			const info: any = await getToken()

			if (info) {
				dirRef.current = info.dir;
				ossHostRef.current = info.ossHost;
				const OSSRef = new OSS({
					region: 'oss-cn-hangzhou',
					accessKeyId: info.accessKeyId,
					accessKeySecret: info.accessKeySecret,
					stsToken: info.securityToken,
					refreshSTSToken: async () => {
						const info: any = await getToken();
						return {
							accessKeyId: info.accessKeyId,
							accessKeySecret: info.accessKeySecret,
							stsToken: info.securityToken
						}
					},
					refreshSTSTokenInterval: 300000,
					bucket: info.bucket
				})
				OSSClientRef.current = OSSRef;
				return OSSClientRef.current;
			}
		}

		return !OSSClientRef.current && initOSS();
	}, [])


	const put = useCallback(async (file: any, type = "ai_model") => {
		try {
			if (hasLogin) {
				const client = OSSClientRef.current || await getOSSClient();
				return new Promise((resolve, reject) => {
					if (client) {	
						const fileReader = new FileReader();
						fileReader.readAsBinaryString(file);
						fileReader.onload = (e: any) => {
							if (file.size === e.target.result.length) {
								const contentMd5 = SparkMD5.hashBinary(e.target.result);
								const formatType = file.type;
								const format = formatType.replace("image/", "");
								const filePath = `${dirRef.current}/${type}/${contentMd5}.${format}`;	
								client.put(filePath, file).then((r1: any) => {
									if (r1.res.statusCode === 200) {
										resolve(`${ossHostRef.current}${filePath}${format !== "webp" ? "?x-oss-process=image/format,webp" : ""}`)
									}
								}).catch((err: any) => {
									reject(false);
								});
							}
						}
					}
				})
			}
		} catch (e) {
		}
	}, [hasLogin])
	return put;
}