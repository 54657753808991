export enum PermissionType {
  batchPictureExpand,// 批量放大权限
  imgToImgSimilarity// 相似度权限
}

export enum PermissionLevel {
  forbid = 0b0000, // 禁止
  read, // 可读
  write // 可操作
}

export const permissionMap = {
  [PermissionType.batchPictureExpand]: PermissionLevel.forbid,
  [PermissionType.imgToImgSimilarity]: PermissionLevel.forbid
}
