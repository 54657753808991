const prefixCdn='https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/';
export const PROTOCOL = {
    privacyPolicy: 'https://aicdn.feilianyun.cn/statics/ai-paint/protocol/%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96.pdf', // 隐私政策
    user: 'https://aicdn.feilianyun.cn/statics/ai-paint/protocol/%E7%94%A8%E6%88%B7%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE1.1.pdf', // 用户协议
    recharge: 'https://aicdn.feilianyun.cn/statics/ai-paint/protocol/%E9%A3%9E%E6%98%9F%E5%85%85%E5%80%BC%E5%8D%8F%E8%AE%AE.pdf', // 飞星充值协议
    noviceTutorialURL: 'https://aicdn.feilianyun.cn/statics/ai-open-pdf/%E9%A3%9E%E9%93%BE%E4%BA%91%E7%89%88%E5%9B%BE%E5%AE%89%E5%85%A8%E9%A3%9F%E7%94%A8%E6%8C%87%E5%8D%971.0.pdf', // 新手教程PDF
    beginnerGuide: 'https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/beginner_tutorial.jpg', // 新手教程
    beginnerIcon: 'https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/button/beginner_tutorial.png', // 新手教程icon
    AiCreate: 'https://aicdn.feilianyun.cn/statics/ai-paint/protocol/AI%E5%88%9B%E4%BD%9C%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.pdf',
    AIModel: 'https://aicdn.feilianyun.cn/statics/ai-paint/protocol/AI%E6%A8%A1%E5%9E%8B%E5%88%9B%E4%BD%9C%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.pdf',
    freePaint: 'https://feilianyun.yuque.com/bu08xt/oifacf/ap4b3fg3wizi3k3x?singleDoc=', // 免费制作
    rebate: 'https://feilianyun.yuque.com/bu08xt/oifacf/ypb9ii4fp6e4ghk8?singleDoc=', // 分销返佣
    withdraw: 'https://aicdn.feilianyun.cn/statics/ai-paint/protocol/%E9%A3%9E%E9%93%BE%E4%BA%91%E9%92%B1%E5%8C%85%E6%8F%90%E7%8E%B0%E5%8D%8F%E8%AE%AE1.0.pdf', // 钱包提现,
    keywords: 'https://aicdn.feilianyun.cn/statics/ai-paint/protocol/%E5%85%B3%E9%94%AE%E8%AF%8D%E8%A7%A3%E9%94%81%E8%B4%AD%E4%B9%B0%E4%B8%8E%E9%94%80%E5%94%AE%E5%8D%8F%E8%AE%AE1.0.pdf', // 飞链云关键词解锁购买与销售协议
}

export const QRCODE  = {
    customer: 'https://osscdn.feilianyunnft.com/imgs/202302/bcf45641bac540f2d820300b425155a6c27d3632c4492a1f6cd014442ecb61a9.png', // 官方客服二维码
    officialAccount: 'https://osscdn.feilianyunnft.com/imgs/202211/77205045ee35762cd54018bbeb66e36af3c1f97e190c8dec3cc4023c8c753676.jpg', // 公众号二维码
    miniProgram: 'https://aicdn.feilianyun.cn/statics/ai-paint/qrcode/miniapp_1.jpg', // 小程序,
    wechat: 'https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/wechat/wechat_group.jpg', // 微信群二维码
    //服务号
    ServiceNumber: 'https://osscdn.feilianyunnft.com/imgs/202212/58b5f2d17af58c2dbaa71326ff2bd283e02c0419ddeb6c7310a75327c6d9de96.jpeg',
}

export const COMMON_URL = {
    ICP: 'https://beian.miit.gov.cn/', // 浙ICP
    GuanWang:"https://feilianyun.cn/",
    publicSecurityFiling: 'https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802012749', // 公安备案号
    empty_data: 'https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/empty_data.png', // 暂无数据
    flyIcon: "https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/fly_coin.png",
    logo: "https://aicdn.feilianyun.cn/statics/flyChat/6fda0d374e63483fa434ac5ff2be20e7.png",
    logo1:  'https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/logo.png',
    slogan: 'https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/slogan.png'
}

export const ButtonURL = {
    super_resolution: 'https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/button/super_resolution.png', // 超分辨率
    download: 'https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/button/download.png', // 下载
    work_exhibition: 'https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/button/work_exhibition.png', // 作品展出
    likes: 'https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/button/likes.png', // 点赞
    not_like: 'https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/button/not_like.png', // 取消点赞
    collect: 'https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/button/collect.png',  // 收藏
    not_collect: 'https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/button/not_collect.png', // 取消收藏
    share_sitting: 'https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/button/share_sitting.png', // 展出设置
    adjusted: 'https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/button/fine-tuning.png', // 微调
    contribute: 'https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/button/contribute.png',
    syncToAlbum: 'https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/button/sync_to_album.png',
    delete: 'https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/button/delete.png',
    keyWord:prefixCdn+'button/keyword.png',
    downloadPDF:prefixCdn+'button/down_pdf.png',
}

export const UserCenterURL = {
    pay_record: 'https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/task_icon/pay_record.png', // 个人中心
    task_center: 'https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/task_icon/task_center.png', // 任务中心
    member_center: 'https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/task_icon/member_center.png', // 会员中心
    novice_tutorial: 'https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/task_icon/novice_tutorial.png', // 新手教程
    sync_to_album: 'https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/button/view_my_gallery.png'
}
export const HomeBannerURL = {
    home_slogan: 'https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/home_slogan.png' // 首页配置
}
export const MemberCenterURL = {
    banner: 'https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/dream-works/background2.png'
}

export const AIFactoryURL= {
    aiModelBg: 'https://aicdn.feilianyun.cn/statics/ai-paint-pc/images/ai_dream_factory.png',
    aiModelDoc: 'https://feilianyun.yuque.com/bu08xt/dhf6ea/gydqbgxgsduin70m?singleDoc=',
    weddingDoc: 'https://feilianyun.yuque.com/bu08xt/dhf6ea/vkes3vsa2z7ia0h9?singleDoc='
}

export const MemberGroup = {
    QRCodeUrl: 'https://aicdn.feilianyun.cn/pc/%E5%BE%AE%E4%BF%A1%E7%BE%A4/%E4%BC%9A%E5%91%98%E7%BE%A4%E4%BA%8C%E7%BB%B4%E7%A0%81.png'
}
