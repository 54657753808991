
import styles from './index.module.scss'
import { Feedback } from '@mui/icons-material'
import { Fab } from '@mui/material'
import React, {useCallback, useState} from 'react'

import FeedbackModal from './components/FeedbakcModal'
import {btn_feedback} from "@/constants/enums/bury";

export default function FeedBack() {

   const [open, setOpen] = useState<boolean>(false)
   const handelClickFeedBack = useCallback(() => {
	   setOpen(true);
	   React.$bury.sendById(btn_feedback);
   }, []);
    return (
        <div className={styles.feedBack}>
            <Fab size="small" onClick={handelClickFeedBack}><Feedback /></Fab>
            <div className={styles.text}>反馈</div>

            <FeedbackModal open={open} onClose={() => setOpen(false)} />
        </div>
    )
}
