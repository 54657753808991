// 邀请好友
import { getSharePoster } from "@/api/feilianyun";
import FlyModal from "@/components/flyModal";
import { downloadByBlob } from "@/utils";
import { DownloadRounded, FileCopy } from "@mui/icons-material";
import { Backdrop, CircularProgress, DialogProps, Stack } from "@mui/material";
import { useRequest } from "ahooks";
import html2canvas from "html2canvas";
import { useRef } from "react";

import React from "react";
import styles from "./index.module.scss";

interface IProps extends Omit<DialogProps, "onClose"> {
	onClose: () => void;
}

export default function ShareModal(props: IProps) {
	const { open, onClose } = props;

    const posterWrapperRef = useRef<HTMLDivElement>(null)

	const { data, loading } = useRequest(getSharePoster, {
		ready: open,
	});

	const handleDownloadPoster = () => {
		downloadByBlob(data!,"飞链云-邀请好友海报");
	};

	const handleCopyPoster = () => {
        if (posterWrapperRef.current) {
            html2canvas(posterWrapperRef.current, {
                useCORS: true, // 【重要】开启跨域配置
                scale:
                    window.devicePixelRatio < 3 ? window.devicePixelRatio : 2,
                allowTaint: true, // 允许跨域图片
            }).then((canvas) => {
                canvas.toBlob((blob: any) => {
                    React.$utils.copyImgToClipboard(blob)
                })
            });
        }
    };

	return (
		<FlyModal
			title="分享海报"
			open={open}
			footer={null}
			onClose={onClose}
			className={styles.sharePosterModal}
		>
			<div
				style={{
					position: "relative",
					paddingBottom: "177.867%",
				}}
                ref={posterWrapperRef}
			>
				<div
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						height: "100%",
						width: "100%",
						backgroundImage: `url(${data})`,
						backgroundSize: "contain",
					}}
				></div>
			</div>

			<Stack
				direction="row"
				spacing={2}
				alignItems="center"
				justifyContent="center"
			>
				<div
					className={styles.downloadPoster}
					onClick={handleDownloadPoster}
				>
					<div className={styles.iconWrapper}>
						<DownloadRounded />
					</div>
					<div className={styles.text}>下载海报</div>
				</div>

				<div
					className={styles.copyPoster}
					onClick={handleCopyPoster}
				>
					<div className={styles.iconWrapper}>
						<FileCopy />
					</div>
					<div className={styles.text}>复制海报</div>
				</div>
			</Stack>
			<Backdrop
				sx={{
					color: "#34d4c7",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={loading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</FlyModal>
	);
}
