import createInstance from "@/api/instance";
import { ListRes, PaginationNew } from "../feilianyun/type";
import { PaintSpeedInfo, TaskSubmitParams } from "./type";

const instance = createInstance(process.env.REACT_APP_FEI_API!);

/**
 * 提交mj绘画任务（画面关键词）
 *
 */
export function mjTask(data: TaskSubmitParams) {
    return instance<string>({
        method: 'POST',
        url: '/mj/imagine',
        data
    });
}

// 关键词解析
export function mjDescribeTask(data: TaskSubmitParams) {
    return instance<string>({
        method: 'POST',
        url: '/mj/describe',
        data
    });
}

// 多图混合
export function mjBlend(data: TaskSubmitParams) {
    return instance<string>({
        method: 'POST',
        url: '/mj/blend',
        data
    });
}

//绘制进度
export function paint_speed_detail(url:string,opts: any) {
    if(url){
        return  instance<PaintSpeedInfo>({
            method: 'GET',
            url: url,
            params: opts
        });
    }
    return Promise.reject(false);
}


//绘制结果
export function paint_result(url:string, opts: any) {
    if(url){
        return  instance<PaintSpeedInfo>({
            method: 'GET',
            url: url,
            params: opts
        });
    }
    return Promise.reject(false);
}


// 放大图片
export function paint_upscale(relatedCode: string, index: number) {
    return  instance<string>({
        method: 'POST',
        url: '/mj/upscale',
        data: { relatedCode, index }
    });
}

export function paint_variation(relatedCode: string, index: number) {
    return  instance<string>({
        method: 'POST',
        url: '/mj/variation',
        data: { relatedCode, index }
    });
}

// 获取创作列表
export function mjList(data: PaginationNew) {
    return  instance<ListRes<PaintSpeedInfo>>({
        method: 'POST',
        url: '/mj/task/query',
        data
    });
}


//查询当前登录用户MJ功能是否可用

export function mjEnable() {
    return  instance({
        method: 'GET',
        url: '/mj/user/enable'
    });
}
