import React from "react";

export function sendPV() {
	const {aplus_queue} = window;
	aplus_queue.push({
		action: 'aplus.sendPV',
		arguments: [{is_auto: false}]
	});
}

export function sendById(id: string, eventParams?: Record<string, any>, eventType = 'CLK') {
	const {aplus_queue} = window;
	aplus_queue.push({
		action: 'aplus.record',
		arguments: [id, eventType, eventParams]
	});
}

export function stopBury() {
	const {aplus_queue} = window;
	aplus_queue.push({
		action: 'aplus.setMetaInfo',
		arguments: ['_hold', 'BLOCK']
	});
}

export function beginBury() {
	// if (process.env.REACT_APP_ENV !== 'production') return
	const {aplus_queue} = window;
	const {userId, name} = React.$store.getState().user;
	if (userId && userId >= 0) {
		aplus_queue.push({
			action: 'aplus.setMetaInfo',
			arguments: ['_user_id', userId]
		});
		aplus_queue.push({
			action: 'aplus.setMetaInfo',
			arguments: ['_user_nick', name]
		});
	}
	aplus_queue.push({
		action: 'aplus.setMetaInfo',
		arguments: ['_hold', 'START']
	});
}
