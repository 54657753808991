import { lazy } from "react";

const LabList = lazy(() => import("@/pages/lab/entry"))
const Lab = lazy(() => import("@/pages/lab"))

const LabRouter = [
    {
        path: '/lab',
        element: <LabList />,
        title: "AI研究基地-飞链云版图"
    },
    {
        path: '/lab/type',
        element: <Lab />
    }
]

export default LabRouter;

