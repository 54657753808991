import { UploadProps } from "@/components/upload/interface";
import { useUploadImg } from "@/hooks/useUploadImg";
import { openLoginModal } from "@/service/user";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Button } from "@mui/material";
import { ChangeEvent, useCallback, useRef, useState } from "react";
import GlobalSnackbar from "../globalDialog/globalSnackbar";
import { FileData } from "./interface";
import styles from "./upload.module.scss";
const unitMB = 1024 * 1024;
export default function Upload(props: UploadProps) {
	const {
		accept,
		maxSize,
		style = {},
		fileList: propFileList = [],
		multiple = false,
		urlArr,
		bucket,
		limit = 1,
	} = props;

	const ossUpload = useUploadImg();
	const [fileList, setFileList] = useState<FileData[]>(propFileList);
	const acceptType =
		accept?.map((item) => `.${item.toLowerCase()}`).join(", ") || "image/*";
	const inputRef = useRef<HTMLInputElement | any>();

	const handleUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files ? Array.from(e.target.files) : null;
		const uploadFiles: FormData[] = [];
		files?.forEach((file: any) => {
			if (file.size <= maxSize!) {
				uploadFiles.push(file);
			} else {
				GlobalSnackbar.show({
					icon: "warning",
					message: `图片尺寸不能大于${maxSize! / unitMB}MB`,
				});
			}
		});
		if (uploadFiles.length) {
			Promise.all(
				uploadFiles.map((file) => ossUpload(file, bucket))
			).then((res) => {
				// oss图片上传失败,打开登陆界面
				if (res[0] == undefined) {
					inputRef.current.value = null;
					openLoginModal()
					return
				}
				const fileData: Array<any> | null =
					files?.map((file, index) => ({
						fileName: file.name,
						fileSize: file.size,
						filePath: res[index],
					})) || null;
				const currentFileList = multiple
					? [...fileList, ...fileData!]
					: [...fileData!];
				setFileList(currentFileList);
				props.onChange?.(currentFileList);
				inputRef.current.value = null;
			});
		}
	};

	const handleDeleteImg = useCallback(
		(index: number) => {
			if (Array.isArray(urlArr) && urlArr.length > 0) {
				urlArr.splice(index, 1);
				fileList.splice(index, 1);
				props.onChange?.(urlArr);
			} else {
				fileList.splice(index, 1);
				props.onChange?.(fileList);
				setFileList((prevState) => [...prevState]);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[fileList, urlArr]
	);

	return (
		<div className={styles.uploadImgWrapper}>
			{(fileList.length > 0 ||
				(Array.isArray(urlArr) && urlArr.length > 0)) && (
				<div className={styles.uploadImgList}>
					{(urlArr || fileList).map((file, index) => {
						return (
							<div
								key={`${file.filePath}_${index}`}
								className={styles.uploadImg}
								style={{
									backgroundImage: `url(${
										file.filePath || file
									})`,
									...style,
								}}
							>
								<DeleteForeverIcon
									className={styles.deleteIcon}
									onClick={() => handleDeleteImg(index)}
								/>
							</div>
						);
					})}
				</div>
			)}

			{
				!!limit && fileList.length < limit &&
				<Button className={styles.upload} component="label" style={style}>
					<input
						hidden
						type="file"
						className={styles.uploadInput}
						accept={acceptType}
						multiple={multiple}
						onChange={handleUploadFile}
						ref={inputRef}
					/>

					<AddPhotoAlternateIcon />
				</Button>
			}
		</div>
	);
}
Upload.defaultProps = {
	maxSize: 2 * 1024 * 1024,
};
