import { renderImperatively } from "../utils/renderImperatively";
import FlyModal from "@/components/flyModal";
import { DialogProps } from "@mui/material";
import {ReactNode} from "react";

export type ModalShowProps = Omit<DialogProps, "open"> & {
    onConfirm?: () => void
    footer?: ReactNode
};

export type DialogShowHandler = {
	close: () => void;
};

export const closeFnSet = new Set<() => void>();

export function confirm(props: ModalShowProps) {
	const handler: DialogShowHandler = renderImperatively(
		// @ts-ignore
		<FlyModal
			{...props}
			onClose={(...args) => {
				closeFnSet.delete(handler.close);
			}}
            onOk={() => {
                handler.close()
                props.onConfirm?.()
            }}
		>
			{props.children}
		</FlyModal>
	);
	closeFnSet.add(handler.close);
	return handler;
}
