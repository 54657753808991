import { lazy } from "react";

const Member = lazy(() => import("@/pages/member"));
const VisaInfinite = lazy(() => import("@/pages/member/visaInfinite"));
const Energy = lazy(() => import("@/pages/energy"));
const Guide = lazy(() => import("@/pages/guide"));
const PersonalCenter = lazy(() => import("@/pages/personal-center"));
const UserInfo = lazy(() => import("@/pages/personal-center/UserInfo"));
const PersonalSettings = lazy(() => import("@/pages/personal-center/settings"));
const Follower = lazy(() => import("@/pages/follower"));

const PersonalCenterRouter = [
	{
		path: "/member",
		element: <Member />,
		title:"会员中心-飞链云版图"
	},
	{
		path: "/member/visaInfinite",
		element: <VisaInfinite />,
		needAuth: true,
		title:"会员中心-飞链云版图"
	},
	{
		path: "/energy",
		element: <Energy />,
		needAuth: true,
		title:"任务中心-飞链云版图"
	},
	{
		path: "/personal-center/:id?",
		element: <PersonalCenter />,
		needAuth: true,
		title:"个人中心-飞链云版图"
	},
	{
		path: "/personal-center/user/:id?",
		element: <UserInfo />,
		needAuth: true,
	},
	{
		path: "/personal-center/settings",
		element: <PersonalSettings />,
		needAuth: true,
		title:"个人资料-飞链云版图"
	},
	{
		path: "/follower",
		element: <Follower />,
	},
	{
		path: "/beginner",
		element: <Guide />,
		title:"新手教程-飞链云版图"
	},
];

export default PersonalCenterRouter;
