import { BootstrapTooltip } from '@/components/common/tooltip';
import HelpIcon from "@mui/icons-material/Help";
import classNames from "classnames";
import { SectionProps } from "./interface";

import { useState } from "react";
import FeedbackModal from "./FeedbackModal";
import "./sectionBox.scss";

export default function SectionBox(props: SectionProps) {
	const {
		title,
		showQuestion,
		renderRightInfo,
		required,
		answers,
		feedback,
		style
	} = props;

	const [open, setOpen] = useState<boolean>(false);

	return (
		<div className="section-box" style={{...style}}>
			{
				title && <div className="section-box-title">
					<div
						className={classNames("title-wrapper", {
							required: required,
						})}
					>
						{title && <h4 className="title-main">{title}</h4>}

						{showQuestion && (
							<BootstrapTooltip
								title={
									<div
										dangerouslySetInnerHTML={{
											__html: answers!,
										}}
									/>
								}
								placement="right"
							>
								<HelpIcon className="question" />
							</BootstrapTooltip>
						)}

						{!!feedback && (
							<div className="feedback" onClick={() => setOpen(true)}>
								{feedback.info}
							</div>
						)}
					</div>
					{renderRightInfo && (
						<div className="title-right">{renderRightInfo}</div>
					)}
				</div>
			}
			<div className="section-box-content">{props.children}</div>
			<FeedbackModal feedback={feedback} open={open} onClose={() => setOpen(false)} />
		</div>
	);
}
