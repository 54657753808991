import { useAppSelector } from "@/app/hooks";
import { HtmlTooltip } from "@/components/common/tooltip";
import { MemberGroup, QRCODE } from "@/constants/config";
import { MemberLevel } from "@/constants/enums/member";
import { Forum } from "@mui/icons-material";
import { Fab, Stack } from "@mui/material";
import styles from "./index.module.scss";

const Communication = () => {

    const { memberMetaInfo } = useAppSelector(
		(state) => state.user
	);

	const renderTitle = (
		<Stack direction="row">
			<div className={styles.communicationWay}>
				<img src={QRCODE.wechat} alt="wechat" />
				<div className={styles.text}>加入绘画群，领取50飞星</div>
			</div>
			{memberMetaInfo?.memberType !== MemberLevel.NORMAL && (
				<div className={styles.communicationWay}>
					<img
						className={styles.QRCodeUrl}
						src={MemberGroup.QRCodeUrl}
						alt="userIcon"
					/>
					<div className={styles.text}>VIP专属会员群</div>
				</div>
			)}
		</Stack>
	);

	return (
		<div className={styles.communication}>
			{/* @ts-ignore */}
			<HtmlTooltip title={renderTitle} placement="left">
				<div className={styles.iconWrapper}>
					<Fab size="small">
						<Forum />
					</Fab>
					<div className={styles.text}>加入社群</div>
				</div>
			</HtmlTooltip>
		</div>
	);
};

export default Communication;
