import {KeepAliveConfig} from "@/router/config";
import {createSlice} from "@reduxjs/toolkit";

const cacheKeys: Record<string, string> = {};

KeepAliveConfig.cacheRoutes.forEach((item, index) => {
    cacheKeys[item] = `${item}_` + index;
})

export interface KeepAlive {
    cacheRoutes: string[]
    cacheKeys: Record<string, string>
}

export const initialState: KeepAlive = {
    cacheRoutes: KeepAliveConfig.cacheRoutes,
    cacheKeys,
}
export const slice = createSlice({
    name: 'keepAlive',
    initialState,
    reducers: {
        update: (state, action) => {
            const oldCacheKey = state.cacheKeys[action.payload];
            if (oldCacheKey) {
                const [path, number] = oldCacheKey.split('_');
                state.cacheKeys = {
                   ...state.cacheKeys,
                   [path]: `${path}_` + (Number(number) + 1)
                }
            }
        },
    }
})

export const keepAliveActions = slice.actions;

export default slice.reducer;