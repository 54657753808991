import { VerticalAlignTop } from "@mui/icons-material";
import { Box, Fab, useScrollTrigger, Zoom } from "@mui/material";

import styles from './index.module.scss'

export default function ScrollTop() {
	const trigger = useScrollTrigger({
		target: window,
		disableHysteresis: true,
		threshold: 100,
	});

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		const anchor = (
			(event.target as HTMLDivElement).ownerDocument || document
		).querySelector("#back-to-top-anchor");

		if (anchor) {
			anchor.scrollIntoView({
				behavior: "smooth",
			});
		}
	};

	return (
		<Zoom in={trigger}>
			<Box
				onClick={handleClick}
				role="presentation"
				className={styles.scrollTop}
			>
				<Fab
					size="small"
					className="backToTop"
				>
					<VerticalAlignTop />
				</Fab>
			</Box>
		</Zoom>
	);
};
