import FooterBar from "@/components/footerBar/footerBar";
import { LayoutProps } from "@/components/layout/interface";
import NavBar from "@/components/navBar/navBar";
import { Stack } from "@mui/material";
import { Suspense } from "react";
import { useLocation } from "react-router-dom";
import VersionUpdateModal from "../versionUpdateModal";
import Communication from "./components/communication";
import FeedBack from "./components/feedback";
import ScrollTop from "./components/scroll-top";
import styles from "./layout.module.scss";

const hideHeaderRoutePath = ["/login", "/edit", "/edit/mobile"];

const hideFooterRoutePath = [
	"/login",
	"/ai/paint",
	"/personal-center",
	"/lab",
	'/mj',
	"/edit",
	"/edit/mobile"
];

const hideSide = ['/lab', '/edit', "/edit/mobile", "/lab/type"];



export default function Layout(props: LayoutProps) {
	const location = useLocation();
	return (
		<div className={styles.layout} id="back-to-top-anchor">
			{!hideHeaderRoutePath.includes(location.pathname) && (
				<div className={styles.header}>
					<NavBar />
				</div>
			)}
			<div className={styles.body}>
				<Suspense fallback={<div />}>{props.children}</Suspense>
				{!(hideFooterRoutePath.includes(location.pathname) || location.pathname.includes("lab")) && (
					<FooterBar />
				)}
			</div>
			{
				!hideSide.includes(location.pathname) && <Stack spacing={1} className="fixed">
					<ScrollTop />
					<Communication />
					{/* <GuideIcon /> */}
					<FeedBack />
				</Stack>
			}
			<VersionUpdateModal />
		</div>
	);
}
