import FlyModal from "@/components/flyModal";
import SectionBox from "@/components/sectionBox/sectionBox";
import { FileData } from "@/components/upload/interface";
import Upload from "@/components/upload/upload";
import { feedbackTypeOptions } from "@/constants/enums";
import Textarea from "@/pages/ai/paint/components/optionMaker/textarea";

import {
	DialogProps,
	FormControl,
	FormHelperText,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import { useState } from "react";

import { addFeedback } from "@/api/feilianyun";
import globalSnackbar from "@/components/globalDialog/globalSnackbar";
import { useRequest } from "ahooks";
import React from "react";
import styles from "./FeedbackModal.module.scss";
import { ErrorConfig, FeedbackItem, FormData } from "./interface";

const DEFAULT_FORM_DATA: FormData = {
	feedbackType: 0,
	contact: "",
	content: "",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const LIMIT = 1024;

const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 3 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

interface IProps extends Omit<DialogProps, "onClose"> {
	onClose: () => void;
}

export default function FeedbackModal(props: IProps) {
	const { open, onClose } = props;

	const [formData, setFormData] = useState<FormData>(DEFAULT_FORM_DATA);
	const [errorConfig, setErrorConfig] = useState<ErrorConfig>();

	const { run: runFeedback } = useRequest(addFeedback, {
		manual: true,
		onSuccess: () => {
			globalSnackbar.show({
				icon: "success",
				message: "反馈成功，客服会尽快联系您",
			});
			handleClose();
		},
	});

	const handleValidateField = (field: string, value: string | number) => {
		if (field === "feedbackType") {
			setErrorConfig((prevState) => ({
				...prevState,
				feedbackType: {
					error: !value,
					helpText: !value ? "类型选择不能为空" : "",
				},
			}));

			return !!value;
		}

		if (field === "contact") {
			const isError = !React.$utils.validatePhone(value as string);
			setErrorConfig((prevState) => ({
				...prevState,
				contact: {
					error: isError,
					helpText: isError ? "请输入正确的手机号码" : "",
				},
			}));

			return !isError;
		}

		if (field === "content") {
			setErrorConfig((prevState) => ({
				...prevState,
				content: {
					error: !value,
					helpText: !value ? "请输入反馈描述" : "",
				},
			}));

			return !!value;
		}

		return true;
	};

	const handleValidateForm = (): boolean => {
		let isValidate = true;
		Object.keys(formData).forEach((field) => {
			const value = (formData as any)[field];
			if (!handleValidateField(field, value)) {
				isValidate = false;
			}
		});

		return isValidate;
	};

	const handleUploadImage = (picture: FileData[] | null) => {
		const pictureList = picture?.map((item) => item.filePath) ?? [];
		handleChangeField("picture", JSON.stringify(pictureList));
	};

	const handleChangeField = (field: string, value: string | number) => {
		setFormData((prevState) => ({
			...prevState,
			[field]: value,
		}));
		handleValidateField(field, value);
	};

	const handleClose = () => {
		onClose();
		setFormData(DEFAULT_FORM_DATA);
	};

	const handleFeedback = () => {
		if (handleValidateForm()) {
			runFeedback({ appSource: "flybt_pc", ...formData! });
		}
	};

	return (
		<FlyModal
			open={open}
			title={<>问题反馈<span
				style={{ marginLeft: "4px,color:rgba(255,255,255,0.5)" }}>
				(反馈被采纳奖励100-999个飞星)</span></>}
			onClose={handleClose}
			onOk={handleFeedback}
			className={styles.feedbackModal}
		>
			<SectionBox title="类型选择">
				<FormControl
					required
					fullWidth
					error={errorConfig?.feedbackType?.error}
				>
					<Select
						placeholder="请选择类型"
						labelId="type-select-label"
						value={formData.feedbackType}
						onChange={(event) =>
							handleChangeField(
								"feedbackType",
								event.target.value
							)
						}
						MenuProps={MenuProps}
					>
						{feedbackTypeOptions.map((item: FeedbackItem) => (
							<MenuItem key={item.value} value={item.value}>
								{item.name}
							</MenuItem>
						))}
					</Select>
					{errorConfig?.feedbackType?.helpText && (
						<FormHelperText>
							{errorConfig?.feedbackType?.helpText}
						</FormHelperText>
					)}
				</FormControl>
			</SectionBox>

			<SectionBox title="联系方式">
				<TextField
					fullWidth
					error={errorConfig?.contact?.error}
					helperText={errorConfig?.contact?.helpText}
					placeholder="请输入联系方式"
					value={formData.contact}
					onChange={(event) =>
						handleChangeField("contact", event.target.value)
					}
				/>
			</SectionBox>

			<SectionBox title="反馈描述">
				<FormControl
					required
					fullWidth
					error={errorConfig?.content?.error}
				>
					<Textarea
						placeholder="请输入反馈描述"
						limit={LIMIT}
						value={formData.content}
						onInput={(value) => handleChangeField("content", value)}
					/>
					{errorConfig?.content?.helpText && (
						<FormHelperText>
							{errorConfig?.content?.helpText}
						</FormHelperText>
					)}
				</FormControl>
			</SectionBox>

			<SectionBox title="上传图片">
				<div className={styles.uploadImgWrapper}>
					<Upload onChange={handleUploadImage} multiple={true} />
				</div>
			</SectionBox>
		</FlyModal>
	);
}
