import counterReducer from '@/app/store/slice/counter';
import createReducer from '@/app/store/slice/create';
import keepReducer from '@/app/store/slice/keepAlive';
import userReducer from '@/app/store/slice/user';
import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

import React from "react";

const middlewares: any = [];

// if (process.env.REACT_APP_ENV !== 'production') {
//     const logger = require('redux-logger');
//     middlewares.push(logger.createLogger())
// }

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        user: userReducer,
        keepAlive: keepReducer,
        create:createReducer,        
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares)
});

React.$store = store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
    >;
