//@ts-nocheck
import { openLoginModal } from "@/service/user";
import { AccountCircle, CircleNotifications, Menu } from "@mui/icons-material";
import {
	Badge,
	Box,
	Button,
	Chip,
	Container,
	Divider,
	Drawer,
	Grid,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Typography
} from "@mui/material";

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useCallback, useMemo, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "./navBar.module.scss";

import { HtmlTooltip } from "@/components/common/tooltip";

import { useAppSelector } from "@/app/hooks";
import GlobalSnackbar from "@/components/globalDialog/globalSnackbar";
import MoreLinkPopover from "@/components/navBar/components/moreLinkPopover";
import UserCenterPopover from "@/components/navBar/components/userCenterPopover";
import { COMMON_URL } from "@/constants/config";
import MessageNotification from "./components/messageNotification";

const dreamUrl = process.env.REACT_APP_TUING_HOST;

export default function NavBar() {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down("lg"));
	const navigate = useNavigate();

	const { phone, headImg, paintTotalNum, balance, integral, tempIntegral } =
		useAppSelector((state) => state.user);

	const [drawOpen, setDrawOpen] = useState(false);

	const [anchorEl, setAnchorEl] = React.useState<Element | null>(
		null
	);

	const [moreLinkAnchorEl, setMoreLinkAnchorEl] = React.useState<Element | null>(
		null
	);
	const [notificationAnchorEl, setNotificationAnchorEl] =
		React.useState<Element | null>(null);

	const linkGroup = useMemo(
		() => [
			{
				name: "首页",
				href: "/",
			},
			{
				name: "AI创作",
				href: "/ai/paint",
			},
			{
				name: (
					<Badge badgeContent={<i className={styles.new}>new</i>}>
						图应AI
					</Badge>
				),
				href: dreamUrl,
				newTab: true,
			},
			{
				name: (
					<Badge badgeContent={<i className={styles.new}>new</i>}>
						AI研究基地
					</Badge>
				),
				href: "/lab",
			},
			{
				name: "个人中心",
				href: "/personal-center",
				hidden: !phone,
			},
		],
		[phone]
	);

	const handleLinkHome = () => {
		navigate("/");
	};

	const handleLogin = () => {
		openLoginModal();
	};

	const handleCloseDraw = useCallback(() => {
		setDrawOpen(false);
	}, []);

	const handleOpenDraw = useCallback(() => {
		setDrawOpen(true);
	}, []);

	const renderMenu = useMemo(() => {
		return (
			<Grid item xs className={styles.menuBox}>
				<Menu onClick={handleOpenDraw} />
			</Grid>
		);
	}, [handleOpenDraw]);

	const handleOpenUserPopover = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleOpenNotification = (event: any) => {
		setNotificationAnchorEl(event.currentTarget);
	};

	const handleShowMoreLink = (event: Element) => {
		setMoreLinkAnchorEl(event.currentTarget)
	}

	const handleLinkToEnergy = () => {
		handleCloseDraw();
		navigate("/energy");
	};

	const handleToComeWithdraw = () => {
		GlobalSnackbar.show({
			icon: "warning",
			message: "提现功能正在开发中，敬请期待",
		});
	};

	//查看vip
	const showVip=()=>{
		try{
			const newWindow: any = window.open('/member', "_blank");
			newWindow.opener = null;
		}catch{

		}
	}

	const PaintTotalNum = () => {
		return (
			<>
				<Typography sx={{ mb: 1 }}>永久飞星：{integral}</Typography>
				<Divider />
				<Typography sx={{ mt: 1 }}>临时飞星：{tempIntegral}</Typography>
			</>
		);
	};

	const renderLoginUser = useMemo(() => {
		return phone ? (
			<div className={styles.personalLogin}>
				<div
					className={styles.avatarAndName}
					onClick={handleOpenUserPopover}
				>
					{headImg ? (
						<img
							src={headImg}
							alt="avatar"
							className={styles.avatar}
						/>
					) : (
						<AccountCircle sx={{ fontSize: "28px" }} />
					)}
				</div>
				<Typography
					noWrap
					sx={{ marginLeft: "6px", maxWidth: "126px" }}
					className={styles.aiPaintingTimes}
					onClick={handleToComeWithdraw}
				>
					余额:￥{balance}
				</Typography>
				<HtmlTooltip title={<PaintTotalNum />}>
					<div
						className={styles.aiPaintingTimes}
						onClick={handleLinkToEnergy}
					>
						<img src={COMMON_URL.flyIcon} alt="flyIcon" />

						{Math.max(paintTotalNum,0)}
					</div>
				</HtmlTooltip>
				<CircleNotifications onClick={handleOpenNotification}/>
			</div>
		) : (<>
			<Chip label="VIP会员限时特惠" color="secondary" onClick={showVip} sx={{mr:1}}></Chip>
			<Button onClick={handleLogin}>登录/注册</Button>
		</>

		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [phone, headImg, balance, PaintTotalNum, paintTotalNum]);

	return (
		<div className={styles.navBar}>
			<Container maxWidth="xl">
				<Grid container spacing={0} justifyContent="space-around">
					<div
						className={styles.iconWrapper}
						onClick={handleLinkHome}
					>
						<div className={styles.iconWrapper}>
							<img
								className={styles.icon}
								src={COMMON_URL.logo}
								alt="飞链云"
							/>
							<div className={styles.iconText}>
								<strong>飞链云版图</strong>
							</div>
						</div>
					</div>
					{matchesSm ? (
						renderMenu
					) : (
						<>
							<Divider orientation="vertical" flexItem />
							<Grid item lg={6} className={styles.linkWrapper}>
								{linkGroup
									.filter((item) => !item.hidden)
									.map((link) => {
										return (
											<NavLink
												target={
													link.newTab
														? "_target"
														: "_self"
												}
												to={link.href}
												className={({ isActive }) =>
													isActive
														? styles.linkActive
														: styles.link
												}
												key={link.href}
											>
												{link.name}
											</NavLink>
										);
									})}
								<div className={styles.more}
									onMouseOver={handleShowMoreLink}
								>
									<span>更多</span>
									<ArrowDropDownIcon className={styles.more_hover} />
								</div>
							</Grid>

							<Grid lg={4} item className={styles.loginWrapper}>
								{renderLoginUser}
							</Grid>
						</>
					)}
				</Grid>
			</Container>
			<Drawer open={drawOpen} onClose={handleCloseDraw}>
				<List>
					<ListItem disablePadding>
						<ListItemText>
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<img
									width="40px"
									height="40px"
									src={COMMON_URL.logo1}
									alt="飞链云"
									style={{ objectFit: "contain" }}
								/>
							</Box>
						</ListItemText>
					</ListItem>
				</List>
				<Divider />
				<List>
					{linkGroup
						.filter((item) => !item.hidden)
						.map((link) => {
							return (
								<ListItem disablePadding key={link.href}>
									<ListItemButton>
										<ListItemText>
											<Box sx={{ padding: "0 20px" }}>
												<NavLink
													to={link.href}
													target={
														link.newTab
															? "_target"
															: "_self"
													}
													className={({ isActive }) =>
														isActive
															? styles.linkActive
															: styles.link
													}
												>
													{link.name}
												</NavLink>
											</Box>
										</ListItemText>
									</ListItemButton>
								</ListItem>
							);
						})}
				</List>
				<Divider />
				<List>
					<ListItem disablePadding>
						<ListItemText>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								{renderLoginUser}
							</Box>
						</ListItemText>
					</ListItem>
				</List>
			</Drawer>

			<UserCenterPopover
				anchorEl={anchorEl}
				open={!!anchorEl}
				onClose={() => {
					handleCloseDraw();
					setAnchorEl(null);
				}}
			/>

			<MoreLinkPopover
				anchorEl={moreLinkAnchorEl}
				onClose={() => {
					setMoreLinkAnchorEl(null);
				}}
			/>

			<MessageNotification
				anchorEl={notificationAnchorEl}
				open={!!notificationAnchorEl}
				onClose={() => {
					handleCloseDraw();
					setNotificationAnchorEl(null);
				}}
			/>
		</div>
	);
}
