import { TouchMidScrollViewProps } from "@/components/touchMidScrollView/interface";
import classNames from "classnames";
import {
	forwardRef,
	useCallback,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import styles from "./touchMidScrollView.module.scss";

function TouchMidScrollView(props: TouchMidScrollViewProps, ref: any) {
	const [smoothMode, setSmoothMode] = useState(true);
	const scrollViewRef = useRef<HTMLDivElement>(null);
	const itemMoveParams = useRef({
		moved: false,
	});

	const handleItemToViewMiddle = useCallback(
		(id: string) => {
			if (itemMoveParams.current.moved) return;
			if (!smoothMode) setSmoothMode(true);
			const rectInfo = scrollViewRef.current
				?.querySelector(`#${id}`)
				?.getBoundingClientRect();
			const boxInfo = scrollViewRef.current!.getBoundingClientRect();
			const boxOffsetLeftHalf = boxInfo.width / 2 + boxInfo.left;
			if (rectInfo) {
				const subLeft = rectInfo.left;
				const subHalfWidth = rectInfo.width / 2;
				const distance = subLeft - boxOffsetLeftHalf + subHalfWidth;

				let currentScrollLeft = scrollViewRef.current?.scrollLeft || 0;
				currentScrollLeft += distance;
				itemMoveParams.current.moved = true;
				setTimeout(() => {
					itemMoveParams.current.moved = false;
				}, 500);
				scrollViewRef.current!.scrollLeft = currentScrollLeft;
			}
		},
		[smoothMode]
	);

	const handleOnWheel = useCallback(
		(e: any) => {
			e.stopPropagation();
			if (smoothMode) {
				setSmoothMode(false);
			}
			const deltaY = e.deltaY;
			if (Math.abs(deltaY) > 0) {
				return;
			}
			if (scrollViewRef.current) {
				scrollViewRef.current.scrollLeft += e.deltaX;
			}
		},
		[smoothMode]
	);

	useImperativeHandle(ref, () => {
		return {
			moveToMid: handleItemToViewMiddle,
		};
	});

	return (
		<div
			className={classNames(styles.touchMidScrollView, {
				[styles.smoothMode]: smoothMode,
			})}
			ref={scrollViewRef}
			onWheel={handleOnWheel}
		>
			{props.children}
		</div>
	);
}

export default forwardRef(TouchMidScrollView);
