import { PermissionLevel, PermissionType } from "@/constants/enums/permissions";
import React from "react";
import {userActions} from "@/app/store/slice/user";
import {UserMemberStatus} from "@/constants/enums/member";
import GlobalDialog from "@/components/globalDialog/globalDialog/globalDialog";
import ConfirmBox from "@/components/confirmBox/confirmBox";

// 移除权限
export function removePermission(type: PermissionType, permissions: PermissionLevel[]) {
  const {user: {userPermissions}} = React.$store.getState();
  const permission = permissions.reduce((pre,next) => pre | next);

  React.$store.dispatch(userActions.setUserPermissions({[type]: userPermissions[type] & (~permission)}))
}

// 增加权限
export function addPermission(type: PermissionType, permissions: PermissionLevel[]) {
  const {user: {userPermissions}} = React.$store.getState();
  const permission = permissions.reduce((pre,next) => pre | next, userPermissions[type])
  React.$store.dispatch(userActions.setUserPermissions({[type]: permission}))
}

// 判断是否有权限
export function hasPermission(type: PermissionType, permissions: PermissionLevel[]) {
  const {user: {userPermissions}} = React.$store.getState()
  const permission = permissions.reduce((pre,next) => pre | next)
  return (userPermissions[type] & permission) === permission;
}

export function showPermissionModal({noAuthTitle = '您还没有该权益', noAuthMsg = '', beforeConfirmCallBack = () => {}} = {}) {
  const {user: {memberStatus}} = React.$store.getState();
  let globalRef: any = {};
  if (!noAuthMsg) {
    if (memberStatus === UserMemberStatus.noMember) {
      noAuthMsg = '请开通会员获得该权益'
    } else {
      noAuthMsg = '请升级会员获得该权益'
    }
  }
  const handleConfirm = () => {
      globalRef.close();
      window.open(`${process.env.REACT_APP_HOST}/member`);
  }
  globalRef = GlobalDialog.show({
      children: <ConfirmBox
          title='暂时无法使用改权益'
          content={
            <div style={{width: '20vw'}}>
                {noAuthMsg}
            </div>
          }
          onConfirm={handleConfirm} />
  })
}
